export const defaults = {
    canvas: {
        width: 1024,
        height: 999,
    },
    stencil: {
        aspectRatio: null,
    },
    file: {
        name: 'cropped',
        append: '',
        type: 'image/jpeg',
        suffix: 'jpg',
    },
    imprint: {
        text: '',
        color: '#ffffff',
        font: 'Arial',
        size: '100',
        alignmentH: 'left',
        alignmentV: 'top',
    },
    legal: {
        color: '#ffffff',
        font: 'Arial',
        size: '10',
    }
}